import './button.scss';

import { Button, ClassNames, IButtonProps, LinkButton } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { Headfirst } from '../utils/headfirst';

export type ButtonVariant =
  | 'white'
  | 'current'
  | 'white-line'
  | 'white-translucent'
  | 'transparent'
  | 'accent-line'
  | 'accent-alt'
  | 'accent-alt-line'
  | 'transparent-accent'
  | 'transparent-black'
  | 'transparent-current'
  | 'transparent-accent-alt'
  | 'grey'
  | 'accent';

export interface IRMButtonProps extends IButtonProps {
  variant?: ButtonVariant;
  to?: string | null;
  noMobile?: boolean;
  activeClassName?: string;
}

export const BTButton: React.FC<IRMButtonProps> = ({
  to,
  variant,
  className,
  children,
  disabled,
  noMobile,
  activeClassName,
  onClick: onClickProp,
  ...buttonProps
}) => {
  // get eventId from /e{EVENT_ID}
  const headfirstEventId = to && Headfirst.getHeadfirstEventId(to);

  const [productSelectorLoading, setProductSelectorLoading] = React.useState(false);

  React.useEffect(() => {
    if (headfirstEventId) {
      // preload event data (stored in cache)
      void Headfirst.getEventById(headfirstEventId);
    }
  }, [headfirstEventId]);

  const onClick = React.useCallback(
    async (event) => {
      if (headfirstEventId) {
        event.preventDefault();

        setProductSelectorLoading(true);
        try {
          await Headfirst.showProductSelector(headfirstEventId);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        setProductSelectorLoading(false);
      }

      if (onClickProp) {
        onClickProp(event);
      }
    },
    [onClickProp, headfirstEventId]
  );

  const props = {
    className: ClassNames.concat('bt-button', className),
    'data-variant': variant,
    ...buttonProps,
    disabled,
    'data-no-mobile': noMobile,
    pending: productSelectorLoading || buttonProps.pending,
    ...buttonProps,
    onClick,
  };

  return to ? (
    <LinkButton {...props} activeClassName={ClassNames.concat('bt-button-wrapper-active', activeClassName)} to={to}>
      {children}
    </LinkButton>
  ) : (
    <Button {...props}>{children}</Button>
  );
};

BTButton.defaultProps = {
  variant: 'white',
};
