// janky, not properly supported way of getting Headfirst checkout integrated - I've got an outstanding ticket in Headfirst to do this properly, but it's unlikely that many other places will need this
// so it's not a priority on HF's roadmap

export namespace Headfirst {
  const eventCache = {};

  let loadStarted = false;

  export const init = () => {
    return new Promise<void>((resolve) => {
      if (!Headfirst.headfirstLoaded() && !loadStarted) {
        loadStarted = true;
        // load the headfirst embed for Bristol Transformed
        const url = 'https://www.headfirstbristol.co.uk/embed2/blank_embed';

        const script = document.createElement('script');
        script.src = url;

        document.body.appendChild(script);

        script.onload = () => {
          if (typeof (window as any).Headfirst_Embed !== 'undefined') {
            (window as any).Headfirst_Embed.init();
            resolve();
            loadStarted = false;
          }
        };
      } else {
        resolve();
      }
    });
  };

  export const headfirstLoaded = () => typeof (window as any).Product_Selector !== 'undefined';

  export const getEventById = async (eventId: string) => {
    if (eventCache[eventId]) {
      return eventCache[eventId];
    }

    const hfEvent = await fetch(`https://www.headfirstbristol.co.uk/api/events.php?event_id=${eventId}&bundle=listing_complete`);
    const hfEventJson = await hfEvent.json();

    eventCache[eventId] = hfEventJson.event;

    return hfEventJson.event;
  };

  export const showProductSelector = async (eventId: string) => {
    await init();

    if (headfirstLoaded()) {
      // get the event from the headfirts api
      const hfEvent = await getEventById(eventId);

      // make event global so it can be accessed by the headfirst script
      globalThis.EVENT = hfEvent;

      (window as any).Product_Selector.show({ event_id: eventId });
    }
  };

  export const isHeadfirstUrl = (url: string) => url.includes('headfirstbristol.co.uk') || url.includes('hdfst.uk');
  export const getHeadfirstEventId = (url: string) => (isHeadfirstUrl(url) ? url.match(/\/e(\d+)/)?.[1] : undefined);
}
