exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-views-blog-post-tsx": () => import("./../../../src/views/blogPost.tsx" /* webpackChunkName: "component---src-views-blog-post-tsx" */),
  "component---src-views-blog-tsx": () => import("./../../../src/views/blog.tsx" /* webpackChunkName: "component---src-views-blog-tsx" */),
  "component---src-views-buy-tsx": () => import("./../../../src/views/buy.tsx" /* webpackChunkName: "component---src-views-buy-tsx" */),
  "component---src-views-document-tsx": () => import("./../../../src/views/document.tsx" /* webpackChunkName: "component---src-views-document-tsx" */),
  "component---src-views-event-tsx": () => import("./../../../src/views/event.tsx" /* webpackChunkName: "component---src-views-event-tsx" */),
  "component---src-views-events-tsx": () => import("./../../../src/views/events.tsx" /* webpackChunkName: "component---src-views-events-tsx" */),
  "component---src-views-festival-event-category-tsx": () => import("./../../../src/views/festivalEventCategory.tsx" /* webpackChunkName: "component---src-views-festival-event-category-tsx" */),
  "component---src-views-festival-event-tsx": () => import("./../../../src/views/festivalEvent.tsx" /* webpackChunkName: "component---src-views-festival-event-tsx" */),
  "component---src-views-festival-programme-tsx": () => import("./../../../src/views/festivalProgramme.tsx" /* webpackChunkName: "component---src-views-festival-programme-tsx" */),
  "component---src-views-festival-timetable-tsx": () => import("./../../../src/views/festivalTimetable.tsx" /* webpackChunkName: "component---src-views-festival-timetable-tsx" */),
  "component---src-views-festival-venue-accessibility-tsx": () => import("./../../../src/views/festivalVenueAccessibility.tsx" /* webpackChunkName: "component---src-views-festival-venue-accessibility-tsx" */),
  "component---src-views-festival-venue-tsx": () => import("./../../../src/views/festivalVenue.tsx" /* webpackChunkName: "component---src-views-festival-venue-tsx" */),
  "component---src-views-home-tsx": () => import("./../../../src/views/home.tsx" /* webpackChunkName: "component---src-views-home-tsx" */),
  "component---src-views-organisation-tsx": () => import("./../../../src/views/organisation.tsx" /* webpackChunkName: "component---src-views-organisation-tsx" */),
  "component---src-views-person-tsx": () => import("./../../../src/views/person.tsx" /* webpackChunkName: "component---src-views-person-tsx" */),
  "component---src-views-redirect-tsx": () => import("./../../../src/views/redirect.tsx" /* webpackChunkName: "component---src-views-redirect-tsx" */),
  "component---src-views-venue-tsx": () => import("./../../../src/views/venue.tsx" /* webpackChunkName: "component---src-views-venue-tsx" */)
}

