import './header.scss';

import { useLocation } from '@reach/router';
import { Globals, useEventListener } from '@rocketmakers/armstrong-edge';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import { useDisableScroll } from '../hooks/useDisableScroll';
import { Routes } from '../utils/routes';
import { BTButton } from './button';

export interface IHeaderProps {
  hideHeaderBackgroundAtTop?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ hideHeaderBackgroundAtTop }) => {
  const headerQueryResponse = useStaticQuery<Queries.headerQuery>(graphql`
    query header {
      prismicGlobal {
        data {
          logo {
            gatsbyImageData(width: 160, placeholder: BLURRED)
          }
        }
      }
      prismicHeader {
        data {
          links {
            text
            link {
              url
            }
          }
        }
      }
    }
  `);

  const [menuOpen, setMenuOpen] = React.useState(false);
  useDisableScroll(menuOpen);

  // set isAtTop to true if on home screen and near top to hide header
  const location = useLocation();
  const [isAtTop, setIsAtTop] = React.useState(hideHeaderBackgroundAtTop);
  const getIsAtTop = React.useCallback(
    () => setIsAtTop(location.pathname === '/' ? !!hideHeaderBackgroundAtTop && (Globals.Window?.scrollY || 0) < 240 : false),
    [hideHeaderBackgroundAtTop, location.pathname]
  );
  useEventListener('scroll', getIsAtTop);

  React.useEffect(() => {
    getIsAtTop();
    setMenuOpen(false);
  }, [location?.pathname]);

  return (
    <>
      {menuOpen && (
        <nav role="menu" className="bt-hamburger" id="menu" aria-labelledby="menubutton">
          {headerQueryResponse.prismicHeader?.data.links?.map((link) => (
            <BTButton variant="transparent" to={link?.link?.url} key={link?.text}>
              {link?.text}
            </BTButton>
          ))}
        </nav>
      )}

      <header className="bt-header" data-is-at-top={isAtTop}>
        <div className="bt-header-bg bt-header-bg-1" />
        <div className="bt-header-bg bt-header-bg-2" />
        <div className="bt-header-inner">
          <Link to={Routes.home}>
            {headerQueryResponse.prismicGlobal?.data?.logo?.gatsbyImageData && (
              <GatsbyImage
                image={headerQueryResponse.prismicGlobal.data.logo.gatsbyImageData as any as IGatsbyImageData}
                alt="Home"
                objectFit="contain"
              />
            )}
          </Link>

          <nav className="bt-header-links">
            {headerQueryResponse.prismicHeader?.data.links?.map((link) => (
              <BTButton variant="transparent" to={link?.link?.url} key={link?.text}>
                {link?.text}
              </BTButton>
            ))}
          </nav>

          <div className="bt-menu-open">
            <BTButton
              variant="transparent"
              onClick={() => setMenuOpen(!menuOpen)}
              aria-haspopup="true"
              aria-controls="menu"
              aria-expanded={menuOpen}
              id="menubutton"
            >
              {menuOpen ? 'close' : 'menu'}
            </BTButton>
          </div>
        </div>
      </header>
    </>
  );
};
