import './shell.scss';

import * as React from 'react';
import Measure from 'react-measure';

import { Headfirst } from '../utils/headfirst';
import { Blobs } from './blobs';
import { Header } from './header';
import { Meta } from './meta';

export interface IShellProps {
  hideHeaderBackgroundAtTop?: boolean;
}

export const Shell: React.FC<React.PropsWithChildren<IShellProps>> = ({ children, hideHeaderBackgroundAtTop }) => {
  const [height, setHeight] = React.useState<number>();

  React.useEffect(() => {
    setTimeout(() => {
      void Headfirst.init();
    }, 2000);
  }, []);

  return (
    <Measure onResize={({ entry }) => entry && setHeight(entry.height)}>
      {({ measureRef }) => (
        <div className="shell" ref={measureRef}>
          <Blobs height={height || 0} />

          <Meta />

          <Header hideHeaderBackgroundAtTop={hideHeaderBackgroundAtTop} />

          {children}
        </div>
      )}
    </Measure>
  );
};
