import './blobs.scss';

import { useLocation } from '@reach/router';
import { Arrays } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { ArrayUtils } from '../utils/arrays';
import { MathsUtils } from '../utils/maths';

interface IBlob {
  side: 'left' | 'right';
  fromEdge: number;
  fromXLine: number;
  size: number;
}

interface IBlobsProps {
  height: number;
}

export const Blobs: React.FunctionComponent<IBlobsProps> = ({ height }) => {
  const { pathname } = useLocation();

  const blobs = React.useMemo<IBlob[]>(
    () =>
      Arrays.repeat<IBlob>(15, () => {
        const side = ArrayUtils.randomFromArray<IBlob['side']>(['left', 'right']);
        return {
          side,
          fromEdge: side === 'left' ? MathsUtils.randomBetween(-150, 150) : MathsUtils.randomBetween(-150, 150),
          fromXLine: MathsUtils.randomBetween(-300, 300),
          size: MathsUtils.randomBetween(800, 1500),
        };
      }),
    [pathname]
  );

  return (
    <div className="blobs" style={{ height, maxHeight: height }}>
      {blobs.map((blob, index) => (
        <div className="blob-wrapper" key={index}>
          <div
            className="blob"
            data-side={blob.side}
            style={{ '--from-edge': `${blob.fromEdge}px`, '--from-x-line': `${blob.fromXLine}px`, '--size': `${blob.size}px` } as any}
          />
        </div>
      ))}
    </div>
  );
};
