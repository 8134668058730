import slugify from 'slugify';

export const getSlug = (name: string) => slugify(name, { lower: true });

export namespace Routes {
  export const home = '/';
  // /festival/festival-name
  export const festival = (name: string) => `/festival/${getSlug(name)}`;

  // /festival/festival-name/programme
  export const festivalProgramme = (festivalName: string, page = 1) => `${festival(festivalName)}/programme${page > 1 ? `/p/${page}` : ''}`;

  // /programme - redirect to current festival programme
  export const programme = '/programme';

  // /festival/festival-name/timetable
  export const festivalTimetable = (festivalName: string) => `${festival(festivalName)}/timetable`;

  // /timetable - redirect to current festival timetable
  export const timetable = `/timetable`;

  // /festival/festival-name/timetable?event=""
  export const festivalTimetableEvent = (festivalName: string, event: string) => `${festivalTimetable(festivalName)}?event=${event}`;

  // /festival/festival-name/events/event-name
  export const festivalEvent = (festivalName: string, eventName: string) => `${festival(festivalName)}/events/${getSlug(eventName)}`;

  // /events/event-name   -  a simpler route used for linkResolver where festival name isn't available, set to redirect
  export const festivalEventWithoutFestival = (eventName: string) => `/festival-events/${getSlug(eventName)}`;

  // /festival/festival-name/venues/venue-name
  export const festivalVenue = (festivalName: string, venueName: string) => `${festival(festivalName)}/venues/${getSlug(venueName)}`;

  // /festival/festival-name/venues/venue-name
  export const venue = (venueName: string) => `/venues/${getSlug(venueName)}`;

  // /festival/festival-name/venues/venue-name/accessibility
  export const festivalVenueAccessibility = (festivalName: string, venueName: string) => `${festivalVenue(festivalName, venueName)}/accessibility`;

  // /festival/festival-name/venues/venue-name/accessibility
  export const venueAccessibility = (venueName: string) => `${venue(venueName)}/accessibility`;

  // /festival/festival-name/category/event-category-name
  export const festivalEventCategory = (festivalName: string, eventCategoryName: string, page = 1) =>
    `${festival(festivalName)}/category/${getSlug(eventCategoryName)}${page > 1 ? `/p/${page}` : ''}`;

  // /festival/festival-name/category/event-category-name
  export const festivalEventCategoryWithoutFestival = (eventCategoryName: string) => `/event-category/${getSlug(eventCategoryName)}`;

  // /events
  export const events = (page = 1) => `/events${page > 1 ? `/p/${page}` : ''}`;

  // /event/event-name
  export const event = (name: string) => `/event/${getSlug(name)}`;

  // /people/person-name
  export const person = (name: string) => `/people/${getSlug(name)}`;

  // /organisation/organisation-name
  export const organisation = (name: string) => `/organisation/${getSlug(name)}`;

  // /blog
  export const blog = (page = 1) => `/blog${page > 1 ? `/p/${page}` : ''}`;

  //  /blog/post/blog-post-name
  export const blogPost = (name: string) => `/blog/post/${getSlug(name)}`;

  // /buy
  export const buy = `/buy`;

  // /document
  export const document = (name: string) => `/document/${getSlug(name)}`;
}
