// eslint-disable-next-line import/no-extraneous-dependencies
import { ArmstrongConfigProvider, ModalProvider, ToastProvider } from '@rocketmakers/armstrong-edge';
import { GatsbyBrowser } from 'gatsby';
import * as React from 'react';

import { LinkWrapper } from './components/linkWrapper';
import { IShellProps, Shell } from './components/shell';

export const Providers: React.FC<React.PropsWithChildren<IShellProps>> = ({ children, hideHeaderBackgroundAtTop }) => {
  return (
    <ArmstrongConfigProvider
      routing={{
        LinkComponent: LinkWrapper,
      }}
    >
      <ModalProvider>
        <ToastProvider autoDismissTime={6000}>
          <Shell hideHeaderBackgroundAtTop={hideHeaderBackgroundAtTop}>{children}</Shell>
        </ToastProvider>
      </ModalProvider>
    </ArmstrongConfigProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <Providers hideHeaderBackgroundAtTop={!!props.pageContext.hideHeaderBackgroundAtTop}>{element}</Providers>;
};
